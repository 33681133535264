import React from 'react'
import { Box } from 'theme-ui'
import ContactFormV1 from '../../../../../gatsby-theme-minimal/src/componentsList/ContactFormV1'
import ContactFormV2 from '../../../../../gatsby-theme-minimal/src/componentsList/ContactFormV2'

export default function ContactFormsContainer(props) {
  const {
    pageObjectKey,
    businessData,
    configData,
    // poweredImages,
    pageTitle,
    isMultiLocationSite
  } = props

  return (
    <>
      {pageObjectKey === 'contact' && (
        <ContactFormV1
          {...props}
          businessData={businessData}
          configData={configData}
          pageTitle={pageTitle}
          isMultiLocationSite={isMultiLocationSite}
          variantName={configData.contactForm.variantName}
          options={configData.contactForm.options}
        />
      )}

      {/* {pageObjectKey === 'menu' || pageObjectKey === 'catering-menu' ? (
        <Box className='contactform2box' sx={{ backgroundColor: '#f7f7f7' }}>
          <ContactFormV2
            formName={pageObjectKey}
            {...props}
            businessData={businessData}
            configData={configData}
            pageTitle={pageTitle}
            isMultiLocationSite={isMultiLocationSite}
            variantName={configData.contactForm.variantName}
            options={{
              title: 'Order? Questions? Get In Touch',
              containerStyles: JSON.stringify({
                variant: 'customVariants.cateringForm',
              }),
            }}
          />
        </Box>
      ) : (
        ''
      )} */}

      {/* //  ! =============================================================*/}
      {/* //  ! WHEN Shaodwing Add Additional Contact Forms Here By Page Key */}
      {/* //  ! =============================================================*/}
    </>
  )
}
