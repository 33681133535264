import React from 'react'
import { Flex, Textarea, Input, Button } from 'theme-ui'

export default function ContactForm({ formName, variantName, city, isMultiLocationSite }) {
  return (
    <Flex
      as="form"
      variant={`${variantName}.formContainer`}
      name={formName ? formName : 'contactformV2'}
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }}
    >
      <Input type="hidden" name="form-name" value={formName ? formName : 'contactformV2'} />

      <Input
        variant={`${variantName}.name`}
        name="name"
        type="text"
        id="name"
        mb={3}
        placeholder="Name"
        className="inputField name"
      />
      <Input
        variant={`${variantName}.email`}
        type="email"
        name="email"
        id="email"
        sx={{ width: '49%' }}
        mb={3}
        placeholder="Email"
        className="inputField email"
      />

      <Input
        variant={`${variantName}.phone`}
        type="phone"
        name="phone"
        id="phone"
        sx={{ width: '49%' }}
        mb={3}
        placeholder="Phone"
        className="inputField phone"
      />

      <Textarea
        name="message"
        id="message"
        variant={`${variantName}.message`}
        rows="6"
        mb={3}
        placeholder="Place your order here and let us know if you have any questions!"
        className="textField message"
      />
      {isMultiLocationSite ? (
        <Input sx={{ display: 'none !important' }} name="location" id="location" value={city} placeholder={city} />
      ) : (
        ''
      )}
      <Button
        sx={{
          variant: 'buttons.secondary',
          color: 'white',
          margin: '0 auto',
          minWidth: '200px',
          textAlign: 'center',
          justifyContent: 'center',
          ':hover': {
            color: 'secondary',
            backgroundColor: 'transparent'
          }
        }}
        className="button submitBtn"
      >
        Submit
      </Button>
    </Flex>
  )
}
