import React, { useEffect, useState } from 'react'
import { Flex, Heading, Text } from '@theme-ui/components'
import Form from '../components/ContactForm/v2/Form'
import ContactWidget from '../components/General/ContactDetails'
import cloudinaryImageOptimizer from '../helpers/cloudinaryImageOptimizer'
export default function ContactFormV2({
  businessData,
  configData,
  options,
  variantName,
  locationIndex,
  isMultiLocationSite
}) {
  const { name, street, city, state, zip, phone } = businessData
  const { containerStyles, title, subtitle, text, contactDetails, email, backgroundImages } = options

  const [cloudinaryImage, setCloudinaryImage] = useState(undefined)

  const styles = containerStyles ? JSON.parse(containerStyles) : {}
  const variant = variantName ? variantName : 'contactFormV2'

  useEffect(() => {
    if (backgroundImages) {
      setCloudinaryImage(
        cloudinaryImageOptimizer(
          backgroundImages[locationIndex],
          window.innerWidth < 1800 ? window.innerWidth + 500 : window.innerWidth
        )
      )
    }

    return () => {}
  }, [])

  return (
    <Flex
      variant={`${variant}.container`}
      sx={{
        background: backgroundImages
          ? `linear-gradient( rgba(0,0,0,0.5),rgba(0,0,0,0.5) ), url('${cloudinaryImage ? cloudinaryImage : ''}')`
          : '',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        ...styles
      }}
    >
      {title && (
        <Heading as="h2" variant={`${variant}.title`} className="title">
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading as="h3" variant={`${variant}.subtitle`} className="subtitle">
          {subtitle}
        </Heading>
      )}
      {text && (
        <Text as="p" variant={`${variant}.text`} className="text">
          {text}
        </Text>
      )}

      {contactDetails && (
        <>
          <ContactWidget
            phone={phone}
            address={{ name, street, city, state, zip }}
            email={email}
            alternativeGoogleMapLink={configData.alternativeGoogleMapLink}
          />
          <br></br>
        </>
      )}

      <Form
        formName={`${name}-${city}`}
        variantName={`${variant}`}
        city={city}
        isMultiLocationSite={isMultiLocationSite}
      />
    </Flex>
  )
}
